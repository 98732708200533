/* -------------------------------- 

Primary style




/* -------------------------------- 

Basic Style

-------------------------------- */

section.stepKYC {
    max-width: 75vw;
    margin: 0 auto;
    background-image:linear-gradient(347deg, #1e1e3f 34%, #1e1e3f 51%, #03a9f4 164%);
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
    border-radius: 48px;
    height: 280px;
    z-index: 10;
}
h2.title {
    margin: 0 0 40px;
    font-size: 56px;
}
.rocketHero, .rocketBounce{
    animation: float 1.7s ease-in-out infinite;
}
.homeBounce{
  animation: float 1.7s ease-in-out infinite;
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
.dextools-about-section.pb-80.md-pb-45 {
  margin-top: 50px;
}

p.kytext {
    margin: -21px auto;
    max-width: 212px;
    font-size: 16px;
    line-height: 23px;
    color: white;
    font-family: 'ClashGrotesk-Light';
}
.cd-breadcrumb, .cd-multi-steps {
    width: 100%;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    margin: 30px auto 9px auto;
    text-align: center;
}
  .cd-breadcrumb:after, .cd-multi-steps:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    float: left;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    /* this is the separator between items */
    display: inline-block;
    content: '\00bb';
    margin: 0 .6em;
    color: #959fa5;
  }
  .cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
  }
  .cd-breadcrumb li > *, .cd-multi-steps li > * {
    /* single step */
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
  }
  .cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    /* selected step */
    color: #96c03d;
  }

  .person a::before {
    /* replace the default arrow separator with a custom icon */
    content: '';
    background: url(/public/assets/images/icons/people.svg);
    background-size: cover;
    background-position: center;
    margin-top: -2px;
    height: 82px;
    width: 82px;
    display: block;
  }
  .ok a::before {
    /* replace the default arrow separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    background: url(/public/assets/images/icons/wallet.svg);
    background-size: cover;
    background-position: center;
    margin-top: -1px;
    height: 82px;
    width: 82px;
    display: block;
  }
  .stepKYC img {
    max-width: 82px;
    margin-top: 33px;
    margin-bottom: 8px;

}
  .wallet em::before {
    /* replace the default arrow separator with a custom icon */
    content: '';
    background: url(/public/assets/images/icons/verify.svg);
    background-size: cover;
    background-position: center;
    margin-top: -1px;
    height: 82px;
    width: 82px;
    display: block;
  }

/*   .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
  } */
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
  }
  .cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
  }
  @media only screen and (min-width: 768px) {
    li.mob{
      display: none;
    }
    .cd-breadcrumb, .cd-multi-steps {
      padding: 0 1.2em;
    }

    .cd-breadcrumb li::after, .cd-multi-steps li::after {
      margin: 0 1em;
    }
    .cd-breadcrumb li > *, .cd-multi-steps li > * {
      font-size: 0.7rem;
    }
  }
  
  /* -------------------------------- 
  
  Triangle breadcrumb
  
  -------------------------------- */
  @media only screen and (min-width: 768px) {
    .cd-breadcrumb.triangle {
      /* reset basic style */
      background-color: transparent;
      padding: 0;
    }
    .cd-breadcrumb.triangle li {
      position: relative;
      padding: 0;
      margin: 4px 4px 4px 0;
    }
    .cd-breadcrumb.triangle li:last-of-type {
      margin-right: 0;
    }
    .cd-breadcrumb.triangle li > * {
      position: relative;
      padding: 1em .8em 1em 2.5em;
      color: #2c3f4c;
      background-color: #edeff0;
      /* the border color is used to style its ::after pseudo-element */
      border-color: #edeff0;
    }
    .cd-breadcrumb.triangle li.current > * {
      /* selected step */
      color: #ffffff;
      background-color: #96c03d;
      border-color: #96c03d;
    }
    .cd-breadcrumb.triangle li:first-of-type > * {
      padding-left: 1.6em;
      border-radius: .25em 0 0 .25em;
    }
    .cd-breadcrumb.triangle li:last-of-type > * {
      padding-right: 1.6em;
      border-radius: 0 .25em .25em 0;
    }

    .cd-breadcrumb.triangle li::after, .cd-breadcrumb.triangle li > *::after {
      /* 
          li > *::after is the colored triangle after each item
          li::after is the white separator between two items
      */
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      content: '';
      height: 0;
      width: 0;
      /* 48px is the height of the <a> element */
      border: 24px solid transparent;
      border-right-width: 0;
      border-left-width: 20px;
    }
    .cd-breadcrumb.triangle li::after {
      /* this is the white separator between two items */
      z-index: 1;
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px);
      border-left-color: #ffffff;
      /* reset style */
      margin: 0;
    }
    .cd-breadcrumb.triangle li > *::after {
      /* this is the colored triangle after each element */
      z-index: 2;
      border-left-color: inherit;
    }
    .cd-breadcrumb.triangle li:last-of-type::after, .cd-breadcrumb.triangle li:last-of-type > *::after {
      /* hide the triangle after the last step */
      display: none;
    }
    .cd-breadcrumb.triangle.custom-separator li::after {
      /* reset style */
      background-image: none;
    }
    .cd-breadcrumb.triangle.custom-icons li::after, .cd-breadcrumb.triangle.custom-icons li > *::after {
      /* 50px is the height of the <a> element */
      border-top-width: 25px;
      border-bottom-width: 25px;
    }
  
    @-moz-document url-prefix() {
      .cd-breadcrumb.triangle li::after,
      .cd-breadcrumb.triangle li > *::after {
        /* fix a bug on Firefix - tooth edge on css triangle */
        border-left-style: dashed;
      }
    }
  }
  /* -------------------------------- 
  
  Custom icons hover effects - breadcrumb and multi-steps
  
  -------------------------------- */

  /* -------------------------------- 
  
  Multi steps indicator 
  
  -------------------------------- */
  @media only screen and (min-width: 768px) {
    .cd-multi-steps {
      /* reset style */
      /* background-color: transparent; */
      padding: 0;
      text-align: center;
    }
  
    .cd-multi-steps li {
      position: relative;
      float: none;
/*       margin: 0em 21.7vw 0em 0px; */
    }
    .cd-multi-steps li:last-of-type {
      margin-right: 0;
    }
    .cd-multi-steps li::after {
      /* this is the line connecting 2 adjacent items */
      position: absolute;
      content: '';
      height: 1px;
      background:  #6ed7fb2b;
      /* reset style */
      margin: 0;
    }
    .cd-multi-steps li.visited::after {
      background-color: #96c03d;
    }
    .cd-multi-steps li > *, .cd-multi-steps li.current > * {
      position: relative;
      color: #2c3f4c;
    }
  
    .cd-multi-steps.custom-separator li::after {
      /* reset style */
      height: 4px;
      background: #edeff0;
    }
  
    .cd-multi-steps.text-center li::after {
      width: 19.7vw;
      top: 50%;
      left: 100%;
      -webkit-transform: translateY(-50%) translateX(-1px);
      -moz-transform: translateY(-50%) translateX(-1px);
      -ms-transform: translateY(-50%) translateX(-1px);
      -o-transform: translateY(-50%) translateX(-1px);
      transform: translateY(-50%) translateX(-1px);
    }
    .cd-multi-steps.text-center li > * {
      z-index: 1;
      border-radius: 50%;
      /* background-color: #edeff047; */
    }
    .cd-multi-steps.text-center li.current > *, .cd-multi-steps.text-center li.visited > * {
      color: #ffffff;
      background-color: #96c03d;
    }
    .cd-multi-steps.text-center.custom-icons li.visited a::before {
      /* change the custom icon for the visited item - check icon */
      background-position: 0 -60px;
    }
  
    .cd-multi-steps.text-top li, .cd-multi-steps.text-bottom li {
      width: 80px;
      text-align: center;
    }
    .cd-multi-steps.text-top li::after, .cd-multi-steps.text-bottom li::after {
      /* this is the line connecting 2 adjacent items */
      position: absolute;
      left: 50%;
      /* 40px is the <li> right margin value */
      width: calc(100% + 40px);
    }
    .cd-multi-steps.text-top li > *::before, .cd-multi-steps.text-bottom li > *::before {
      /* this is the spot indicator */
      content: '';
      position: absolute;
      z-index: 1;
      left: 50%;
      right: auto;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #edeff0;
    }
    .cd-multi-steps.text-top li.visited > *::before,
    .cd-multi-steps.text-top li.current > *::before, .cd-multi-steps.text-bottom li.visited > *::before,
    .cd-multi-steps.text-bottom li.current > *::before {
      background-color: #96c03d;
    }

    .cd-multi-steps.text-top li::after {
      /* this is the line connecting 2 adjacent items */
      bottom: 4px;
    }
    .cd-multi-steps.text-top li > * {
      padding-bottom: 20px;
    }
    .cd-multi-steps.text-top li > *::before {
      /* this is the spot indicator */
      bottom: 0;
    }
  
    .cd-multi-steps.text-bottom li::after {
      /* this is the line connecting 2 adjacent items */
      top: 3px;
    }
    .cd-multi-steps.text-bottom li > * {
      padding-top: 20px;
    }
    .cd-multi-steps.text-bottom li > *::before {
      /* this is the spot indicator */
      top: 0;
    }
  }
  /* -------------------------------- 
  
  Add a counter to the multi-steps indicator 
  
  -------------------------------- */
  .cd-multi-steps.count li {
    counter-increment: steps;
  }
  
  .cd-multi-steps.count li > *::before {
    content: counter(steps) " - ";
  }
  
  @media only screen and (min-width: 768px) {
    .cd-multi-steps.text-top.count li > *::before,
    .cd-multi-steps.text-bottom.count li > *::before {
      /* this is the spot indicator */
      content: counter(steps);
      height: 26px;
      width: 26px;
      line-height: 26px;
      font-size: 1.4rem;
      color: #ffffff;
    }
  
    .cd-multi-steps.text-top.count li:not(.current) em::before,
    .cd-multi-steps.text-bottom.count li:not(.current) em::before {
      /* steps not visited yet - counter color */
      color: #2c3f4c;
    }
  
    .cd-multi-steps.text-top.count li::after {
      bottom: 11px;
    }
  
    .cd-multi-steps.text-top.count li > * {
      padding-bottom: 34px;
    }
  
    .cd-multi-steps.text-bottom.count li::after {
      top: 11px;
    }
  
    .cd-multi-steps.text-bottom.count li > * {
      padding-top: 34px;
    }
  }
  